import {observable, action, runInAction} from 'mobx';
import axios from 'axios';

export default class SettingsStore {
  @observable coachApplicationsSettings = {};
  @observable isLoading = false;

  @action loadCoachApplicationsSettings = async () => {
    this.isLoading = true;
    try {
      const {data} = await axios.get(
        `${process.env.REACT_APP_API_URL}/coach_applications/settings`
      );
      runInAction(() => {
        this.coachApplicationsSettings = data;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };
}
