import React, {useEffect, useState} from 'react';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import last from 'lodash/last';
import store from 'store';
import sortBy from 'lodash/sortBy';
import {Formik, useFormikContext} from 'formik';
import {inject, observer} from 'mobx-react';
import {useLocation} from 'react-router-dom';
import queryString from 'query-string';
import * as moment from 'moment-timezone';
import timezones from '../../const/timezones';
import Loading from '../Loading';
import Button from '../Page/Button';
import Link from '../Link';
import FormError from '../Form/FormError';
import ApplicationFormFields, {validateForm, applicationFields} from './ApplicationFormFields';
import InputError from '../Form/InputError';
import AssessmentCenteredPage from '../Page/AssessmentCenteredPage';
import OptInForm from '../OptInForm';

const STORE_KEY = '100x_application_data';

// get current timezone from the timezone list. If not found get the timezone with the same offset and biggest population
const currentTimezone = (
  timezones.find(({value}) => value === moment.tz.guess()) ||
  last(
    sortBy(
      timezones.filter(({offset}) => offset === moment.tz(moment.tz.guess()).format('Z')),
      (timezone) => moment.tz.zone(timezone.value).population
    )
  )
)?.value;

const ApplicationPage = ({applicationStore, settingsStore, gaw, law}) => {
  const [redirecting, setRedirecting] = useState(false);
  const [optinData, setOptinData] = useState(null);
  const location = useLocation();
  const query = queryString.parse(location.search);

  let storedValues = store.get(STORE_KEY);

  if (!storedValues?.key || storedValues.key !== query.key) {
    storedValues = {};
  }
  const coachApplicationsSettings = settingsStore.coachApplicationsSettings;
  const isCoachApplicationsDisabled = coachApplicationsSettings?.enabled === false;
  const isLoadingCoachApplicationSettings = settingsStore?.isLoading;

  useEffect(() => {
    if (isCoachApplicationsDisabled) {
      window.location.href = 'https://www.positiveintelligence.com/100x-after-deadline/';
    }
  }, [isCoachApplicationsDisabled]);

  useEffect(() => {
    document.title = 'Enrollment Now Open for PQ Coaching Grant Program';
  }, []);

  useEffect(() => {
    settingsStore.loadCoachApplicationsSettings();
  }, [settingsStore]);

  // reset the scroll position back if we redirected with stored values
  useEffect(() => {
    if (storedValues.scrollY) {
      setTimeout(() => {
        window.scrollY !== storedValues.scrollY && window.scroll(0, storedValues.scrollY);
      }, 100);
    }
  }, [storedValues.scrollY]);

  const handleSubmitForm = async (values, actions) => {
    const nonPaid = !law && !gaw;
    try {
      const data = pickBy(pick(values, applicationFields), (v) => v !== '');
      data.utm = {
        source: query.utm_source ?? (nonPaid ? 'Website' : 'CPC'),
        medium: query.utm_medium ?? (gaw ? 'Google' : law ? 'LinkedIn' : 'Organic'),
        campaign: query.utm_campaign ?? '100x',
        content: query.utm_content ?? '100x-Application-Form',
        term: query.utm_term ?? 'Coaching',
        matchtype: query.utm_matchtype,
        gclid: query.gclid,
        ad_id: query.ad_id
      };

      await applicationStore.submit(data);

      setRedirecting(true);
      window.location.href = 'https://positiveintelligence.lpages.co/100x-application-submitted/';
    } catch (err) {
      if (err.response?.data?.message) {
        if (err.response?.data?.code === 'BR043') {
          handleNeedToOptIn(values);
        } else {
          actions.setErrors({form: err.response.data.message});
        }
      }
    }
  };

  const handleNeedToOptIn = (values) => {
    // store the data to populate it back when the user is redirected after the optin process is completed
    const key = (Math.random() + '').replace('.', '');
    setOptinData({
      backUrl: window.location.href.split('?')[0] + '?' + queryString.stringify({...query, key}),
      email: values.email
    });
    store.set(STORE_KEY, {key, values, scrollY: window.scrollY, optedIn: true});
  };

  const initialValues = storedValues.values || {
    ...applicationFields.reduce((fields, field) => ({...fields, [field]: ''}), {}),
    country: gaw || law ? 'US' : '',
    description: gaw ? 'google_adwords' : law ? 'linkedin_ad' : '',
    is_coach_confirmation: false,
    requirements_read_confirmation: false,
    sms_enabled: true,
    timezone: currentTimezone
  };

  const hiddenFields =
    gaw || law ? ['country', 'description', 'referrer', 'timezone', 'coach_certifications'] : [];

  return (
    <AssessmentCenteredPage>
      {isLoadingCoachApplicationSettings ? <Loading /> : null}
      {optinData && (
        <OptInForm
          email={optinData.email}
          backUrl={optinData.backUrl}
          onOptIn={() => (window.location.href = optinData.backUrl)}
        />
      )}
      <h2 className='heading-4 lg:heading-3'>
        Enrollment Now Open for the PQ Coaching Grant Program
      </h2>
      <div className='body mt-50'>
        <p>
          Thank you for your interest in Positive Intelligence's multi-million dollar coaching
          grant. I want to help you dramatically increase both the number of clients you can attract
          and the impact you make on each client by using our PQ system and tools. Please review the
          eligibility requirements below, then submit your application by completing the form below.
        </p>

        <p>
          Questions?{' '}
          <Link href='https://support.positiveintelligence.com/'>
            Search our FAQs or contact us here
          </Link>
          .
        </p>

        <p>
          Coach Shirzad
          <br />
          <span className='text-black-57'>
            Author of the <i>New York Times</i> bestseller, <i>Positive Intelligence</i>
          </span>
        </p>

        <h3 className='heading-4 mt-50'>PQ Coaching Grant Eligibility Requirements</h3>

        <div className='mt-40'>
          In order to be eligible for this grant and entry into our 7-week program, you MUST:
          <ol className='my-30 ml-30 list-decimal'>
            <li>
              <strong>Be an active, professional coach.</strong> This could mean you own a coaching
              business, you coach as a side hustle, or you are in a coaching role within your
              company.
            </li>
            <li className='mt-15'>
              <strong>
                Participate with an accountability group of 2-4 other professional coaches.
              </strong>{' '}
              Research proves you’re 500% more likely to succeed in our program when you partner
              with buddies who can offer accountability and support.{' '}
              <i>
                Any coach you select for your group will need to complete this application too, so
                make sure you forward it along
              </i>
              .
            </li>
            <li className='mt-15'>
              <strong>Fully commit to the program throughout its duration.</strong> Once the program
              kicks off, we expect those who are participating to make this a priority. That means
              investing in your success by setting aside a few hours each week for the lessons and
              exercises. Here’s what you can expect in terms of time commitment:
              <ul className='ml-30 list-disc'>
                <li>Weekly video sessions (1 hour)</li>
                <li>Daily app-guided mental exercises (15 minutes)</li>
                <li>Weekly check-in meetings with your accountability pod group (30 minutes)</li>
                <li>Weekly Q&A session with Coach Shirzad (optional, 45 minutes)</li>
              </ul>
            </li>
          </ol>
        </div>
      </div>
      <Formik initialValues={initialValues} validate={validateForm} onSubmit={handleSubmitForm}>
        <ApplicationForm
          redirecting={redirecting}
          hiddenFields={hiddenFields}
          isOptedIn={storedValues.optedIn}
        />
      </Formik>
      <p>
        Questions?{' '}
        <Link href='https://support.positiveintelligence.com/'>
          Search our FAQs or contact us here
        </Link>
        .
      </p>
    </AssessmentCenteredPage>
  );
};

const ApplicationForm = ({redirecting, hiddenFields, isOptedIn}) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    submitForm,
    isSubmitting,
    setFieldValue,
    submitCount
  } = useFormikContext();

  // auto submit the form if the user is opted in
  useEffect(() => {
    if (isOptedIn) {
      setFieldValue('opted_in', true);
      submitForm();
    }
  }, [isOptedIn, setFieldValue, submitForm]);

  return (
    <div className='body'>
      <form onSubmit={handleSubmit}>
        {isSubmitting || redirecting ? <Loading fixed /> : null}

        <div
          className={`${
            errors.is_coach_confirmation && touched.is_coach_confirmation ? 'text-orange' : ''
          }`}
        >
          <input
            type='checkbox'
            id='is_coach_confirmation'
            name='is_coach_confirmation'
            onChange={handleChange}
            value='true'
            checked={values.is_coach_confirmation}
            className='mr-10'
          />
          <label htmlFor='is_coach_confirmation'>
            I confirm I am an active, professional coach
          </label>
          <InputError>
            {errors.is_coach_confirmation &&
              touched.is_coach_confirmation &&
              errors.is_coach_confirmation}
          </InputError>
        </div>

        <div
          className={`${
            errors.requirements_read_confirmation && touched.requirements_read_confirmation
              ? 'text-orange'
              : ''
          }`}
        >
          <input
            type='checkbox'
            id='requirements_read_confirmation'
            name='requirements_read_confirmation'
            onChange={handleChange}
            value='true'
            checked={values.requirements_read_confirmation}
            className='mr-10'
          />
          <label htmlFor='requirements_read_confirmation'>
            I have read and understand the requirements for the PQ Coaching Grant Program
          </label>
          <InputError>
            {errors.requirements_read_confirmation &&
              touched.requirements_read_confirmation &&
              errors.requirements_read_confirmation}
          </InputError>
        </div>

        <div className='heading-4 mt-50'>Application Form</div>

        <ApplicationFormFields
          {...{
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            hiddenFields
          }}
        />

        {errors.form && !!submitCount ? (
          <FormError className='mt-30'>{errors.form}</FormError>
        ) : null}
        <Button
          className='mt-20'
          tabIndex={0}
          label='Submit Application'
          onClick={submitForm}
          onEnter={submitForm}
          disabled={isSubmitting || redirecting}
        />
      </form>
    </div>
  );
};

export default inject('applicationStore', 'settingsStore')(observer(ApplicationPage));
