import React, {Component} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {Provider} from 'mobx-react';

import SaboteurAssessmentStore from '../model/SaboteurAssessmentStore';
import SabEntryPage from './SaboteurAssessment/EntryPage';
import SabOverviewPage from './SaboteurAssessment/OverviewPage';
import SabInstructionsPage from './SaboteurAssessment/InstructionsPage';
import SabSignUpPage from './SaboteurAssessment/SignUpPage';
import SabSavedResultsPage from './SaboteurAssessment/SavedResultsPage';

import SabConfirmPage from './SaboteurAssessment/ConfirmPage';
import SabAssessmentPage from './SaboteurAssessment/AssessmentPage';
import SabLearnMorePage from './SaboteurAssessment/LearnMorePage';
import SabAssessmentResultPage from './SaboteurAssessment/AssessmentResultPage';
import SabAssessmentResultScorePage from './SaboteurAssessment/AssessmentResultScorePage';
import SabAssessmentResultDetailsPage from './SaboteurAssessment/AssessmentResultDetailsPage';

import CaptchaStore from '../model/CaptchaStore';
import PQAssessmentStore from '../model/PQAssessmentStore';
import PQEntryPage from './PQAssessment/EntryPage';
import PQOverviewPage from './PQAssessment/OverviewPage';
import PQDefinitionPage from './PQAssessment/DefinitionPage';
import PQInstructionsPage from './PQAssessment/InstructionsPage';
import PQAssessmentPage from './PQAssessment/AssessmentPage';
import PQSignUpPage from './PQAssessment/SignUpPage';
import PQSavedResultsPage from './PQAssessment/SavedResultsPage';
import PQConfirmPage from './PQAssessment/ConfirmPage';
import PQAssessmentResultPage from './PQAssessment/AssessmentResultPage';
import PQAssessmentResultPQMeaningPage from './PQAssessment/AssessmentResultPQMeaningPage';
import PQAssessmentResultMeaningPage from './PQAssessment/AssessmentResultMeaningPage';
import PQAssessmentResultVortexPage from './PQAssessment/AssessmentResultVortexPage';
import AssessmentResultTippingPointPage from './PQAssessment/AssessmentResultTippingPointPage';
import PQAssessmentResultBoostPage from './PQAssessment/AssessmentResultBoostPage';
import PQAssessmentResultImpactPage from './PQAssessment/AssessmentResultImpactPage';

import ApplicationStore from '../model/ApplicationStore';
import SettingsStore from '../model/SettingsStore';

import PodStore from '../model/PodStore';
import PodPage from './Pod/PodPage';

import NotFoundPage from './NotFoundPage';
import ScrollToTopRoute from './ScrollToTopRoute';

import OptInPage from './OptInPage';
import OptedInPage from './OptedInPage';
import ApplicationSubmittedPage from './Application/ApplicationSubmittedPage';
import UpdateApplicationPage from './Application/UpdateApplicationPage';
import UpdateApplicationPodPage from './Application/UpdateApplicationPodPage';
import ApplicationPage from './Application/ApplicationPage';

class RootStore {
  constructor() {
    this.captchaStore = new CaptchaStore();
    this.podStore = new PodStore();
    this.applicationStore = new ApplicationStore();
    this.settingsStore = new SettingsStore();
    this.saboteurAssessmentStore = new SaboteurAssessmentStore(this);
    this.pqAssessmentStore = new PQAssessmentStore(this);
  }
}

const rootStore = new RootStore();

export default class App extends Component {
  render() {
    return (
      <Provider
        sabStore={rootStore.saboteurAssessmentStore}
        pqStore={rootStore.pqAssessmentStore}
        applicationStore={rootStore.applicationStore}
        settingsStore={rootStore.settingsStore}
        podStore={rootStore.podStore}
      >
        <Switch>
          <Route
            exact
            path='/'
            render={() => {
              return <Redirect to='/saboteur' />;
            }}
          />

          <Route exact path='/opt_in' component={OptInPage} />
          <Route exact path='/opted_in' component={OptedInPage} />

          <Route exact path='/saboteur' component={SabEntryPage} />
          <ScrollToTopRoute exact path='/saboteur/overview' component={SabOverviewPage} />
          <ScrollToTopRoute exact path='/saboteur/instructions' component={SabInstructionsPage} />
          <Route exact path='/saboteur/assessment/:questionNo' component={SabAssessmentPage} />
          <Route exact path='/saboteur/save' component={SabSignUpPage} />
          <Route exact path='/saboteur/confirm/:resultId' component={SabConfirmPage} />
          <Route exact path='/saboteur/saved/:resultId' component={SabSavedResultsPage} />
          <Route exact path='/saboteur/update/:resultId' component={SabSignUpPage} />
          <ScrollToTopRoute
            exact
            path='/saboteur/results/:resultId/more'
            component={SabLearnMorePage}
          />
          <ScrollToTopRoute
            exact
            path='/saboteur/results/:resultId/saboteurs'
            component={SabAssessmentResultDetailsPage}
          />
          <ScrollToTopRoute
            exact
            path='/saboteur/results/:resultId/saboteurs/:saboteur'
            component={SabAssessmentResultDetailsPage}
          />
          <ScrollToTopRoute
            exact
            path='/saboteur/results/:resultId/score'
            component={SabAssessmentResultScorePage}
          />
          <ScrollToTopRoute
            exact
            path='/saboteur/results/:resultId'
            component={SabAssessmentResultPage}
          />

          <Route exact path='/pq' component={PQEntryPage} />
          <ScrollToTopRoute exact path='/pq/overview' component={PQOverviewPage} />
          <ScrollToTopRoute exact path='/pq/definition' component={PQDefinitionPage} />
          <ScrollToTopRoute exact path='/pq/instructions' component={PQInstructionsPage} />
          <Route exact path='/pq/assessment/:questionNo' component={PQAssessmentPage} />
          <ScrollToTopRoute exact path='/pq/save' component={PQSignUpPage} />
          <ScrollToTopRoute exact path='/pq/confirm/:resultId' component={PQConfirmPage} />
          <ScrollToTopRoute exact path='/pq/saved/:resultId' component={PQSavedResultsPage} />
          <ScrollToTopRoute exact path='/pq/update/:resultId' component={PQSignUpPage} />
          <ScrollToTopRoute
            exact
            path='/pq/results/:resultId/pq_meaning'
            component={PQAssessmentResultPQMeaningPage}
          />
          <ScrollToTopRoute
            exact
            path='/pq/results/:resultId/meaning'
            component={PQAssessmentResultMeaningPage}
          />
          <ScrollToTopRoute
            exact
            path='/pq/results/:resultId/vortex'
            component={PQAssessmentResultVortexPage}
          />
          <ScrollToTopRoute
            exact
            path='/pq/results/:resultId/tipping_point'
            component={AssessmentResultTippingPointPage}
          />
          <ScrollToTopRoute
            exact
            path='/pq/results/:resultId/impact'
            component={PQAssessmentResultImpactPage}
          />
          <ScrollToTopRoute
            exact
            path='/pq/results/:resultId/boost'
            component={PQAssessmentResultBoostPage}
          />
          <ScrollToTopRoute exact path='/pq/results/:resultId' component={PQAssessmentResultPage} />
          <Route exact path='/application'>
            <ApplicationPage />
          </Route>
          <Route exact path='/application_g_aw'>
            <ApplicationPage gaw />
          </Route>
          <Route exact path='/application_li_aw'>
            <ApplicationPage law />
          </Route>
          <Route exact path='/application/submitted' component={ApplicationSubmittedPage} />
          <Route exact path='/application/:updateSecret' component={UpdateApplicationPage} />
          <Route exact path='/application/:updateSecret/pod' component={UpdateApplicationPodPage} />
          <Route exact path='/pods/:secret' component={PodPage} />

          <Route component={NotFoundPage} />
        </Switch>
      </Provider>
    );
  }
}
